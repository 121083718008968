
import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import Grid from '@mui/material/Grid2';

function Footer() {
  return (
    <Box sx={{ bgcolor: 'primary.main', color: 'white', padding: '20px 0', marginTop: 'auto' }}>
      <Grid container spacing={25} justifyContent="center">
        <Grid item>
          <Typography variant="h6">Quick Links:</Typography>
          <Link href="/" color="inherit">Home</Link><br />
          <Link href="/products" color="inherit">Products</Link><br />
          <Link href="/tnc" color="inherit" target='_blank'>Terms and Conditions</Link><br />
          {/* <Link href="/services" color="inherit">Services</Link><br /> */}
          {/* <Link href="/about" color="inherit">About Us</Link><br /> */}
          {/* <Link href="/contact" color="inherit">Contact Us</Link> */}
        </Grid>

        <Grid item>
          <Typography variant="h6">Contact Us:</Typography>
          <Typography>Email: omnevu@gmail.com</Typography>
          {/* <Typography>Phone: +123456789</Typography> */}
        </Grid>

        <Grid item>
          <Typography variant="h6">Follow Us:</Typography>
          <Link href="#" color="inherit">Facebook</Link><br />
          <Link href="#" color="inherit">Twitter</Link><br />
          <Link href="#" color="inherit">Instagram</Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;

