import { AppBar, Toolbar, Typography, Container, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

import Logo from "../logos/OmneVu logo.png";

export default function NavBar() {

    return (
        <>

            <AppBar position="static"
                   sx={{
                    // bgcolor: 'white',
                    // color: 'primary.main',
                    fontWeight: 'bold',
                    width:'100%',
                    // maxWidth:600,
                    mx:'auto'
                    // display:'flex'
                }}
              >
                
                    <Toolbar
                   >
                        {/* <Typography variant="h6" sx={{ flexGrow: 1 }}>
              OmneVu.com
            </Typography> */}
                        <Box sx={{bgcolor:'white'}}>
                        <Link to="/"><Box
                            component="img"
                            sx={{
                                height: 50,
                                width: 75,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',

                            }}
                            alt="OmneVu.com"
                            src={Logo}

                        />
                        </Link>
                        </Box>
                        <Box width='60%' sx={{ml:3}}>
                            <Typography variant='h4' fontWeight='bold'>OmnéVu</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'right',
                                
                                

                            }}
                        >

                            <Button color="inherit" component={Link} to="/">Home</Button>
                            <Button color="inherit" component={Link} to="/products">Products</Button>
                            {/* <Button color="inherit" component={Link} to="/services">Services</Button> */}
                            <Button color="inherit" component={Link} to="/about">About Us</Button>
                            <Button color="inherit" component={Link} to="/contact">Contact Us</Button>
                        </Box>
                    </Toolbar>
                
            </AppBar>


        </>
    )

}