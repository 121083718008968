import { Box, Chip, Divider, Stack, Typography } from "@mui/material";
import CarouselComponent from "../components/Carousel";
import Footer from '../components/Footer/Footer';

import NavBar from "../components/NavBar";
import HeroImage from "../logos/HeroImage.jpg";
import { Link } from "react-router-dom";



export default function HomePg() {

    return (
        <>
            <NavBar />
            <Box sx={{ m: 5 }}>
                <Typography variant="h4"><b>Your One-Stop Solution for Business Success</b></Typography>
                <Typography variant="h6">Streamline Your Operations, Elevate Your Brand</Typography>
                <br />
                <Divider sx={{ borderBottomWidth: 7, bgcolor: 'primary.main' }} />

            </Box>
            <Box component='img' src={HeroImage} alt='Group Image'
                sx={{
                    height: "450px", width: '90%',
                    ml: 5, mb: 3, border: 2
                }}></Box>
            <br />
            <Divider sx={{ borderBottomWidth: 3, bgcolor: 'primary.main' }} />
            <p><Typography variant="h6" ml={5} color="primary.main"><b>Revolutionize Your Retail Operations:</b></Typography></p>

            <CarouselComponent />
            {/* <Divider sx={{ borderBottomWidth: 3, bgcolor: 'primary.main' }} /> */}
            <Box
                sx={{ ml: 5, mt:3 }}>
                <p><b>Agile Project Planning:</b> Embrace flexibility and adaptability with our agile project management framework.</p>
                <p><b>Inventory Management:</b> Optimize stock levels, track product movements, and reduce costs with our advanced inventory system.</p>
                <p><b>Customer Profiles / Market Research / Assessments:</b> Gain deep insights into customer behavior, preferences, and purchase history to personalize your marketing efforts.</p>
                <p><b>Real-time News and Updates:</b> Stay ahead of industry trends and market fluctuations with our timely news feed.</p>
                <p><b>Efficient Time Booking:</b> Schedule appointments, allocate resources, and maximize productivity with our intuitive time booking tool.</p>

                <p><b>Skill Assessment and Development:</b> Identify talent gaps, upskill your team, and foster a culture of continuous learning.</p>
                <p><b>Warranty Management:</b> Simplify warranty claims, track product lifecycles, and enhance customer satisfaction.</p>
            </Box>
            <br />
            <Divider sx={{ borderBottomWidth: 2, bgcolor: 'primary.main' }} />
            <br />
            <Stack direction="row" spacing={2} ml={5}>
                <Typography variant="h6" color="primary.main" ml={5}><b>Your Business, Reimagined:</b></Typography>


                <Link to="products"><b><Chip label='&nbsp; &nbsp; &nbsp;Explore More&nbsp; &nbsp; &nbsp;' color="success"></Chip></b></Link>
            </Stack>
            <br />
            <Footer />
        </>
    )
}